import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./Checkbox.module.css"

import { Tick } from "~/common/react-icons"

type Props = Omit<React.HTMLProps<HTMLInputElement>, "label"> & {
  id: string
  containerClassName?: string
  label?: string | React.ReactElement
  labelClassName?: string
  tickClassName?: string
  minusClassName?: string
  indeterminate?: boolean
}

const Checkbox = (props: Props) => {
  const {
    id,
    containerClassName,
    label,
    labelClassName,
    tickClassName,
    minusClassName,
    indeterminate,
    ...restProps
  } = props

  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!indeterminate
    }
  }, [indeterminate])

  return (
    <div
      className={cc([styles.container, containerClassName])}
      data-component="Checkbox"
    >
      <input
        ref={inputRef}
        id={id}
        className={styles.checkboxInput}
        type="checkbox"
        {...restProps}
      />
      <Tick
        className={cc([styles.checkboxTick, tickClassName])}
        color="white"
      />
      <Icon
        icon="minus"
        size={16}
        color="white"
        className={cc([styles.checkboxMinus, minusClassName])}
      />
      {label && (
        <label htmlFor={id} className={cc([styles.label, labelClassName])}>
          {label}
        </label>
      )}
    </div>
  )
}

export default Checkbox
