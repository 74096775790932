import cc from "classcat"
import React, { useCallback, useState } from "react"

import styles from "./Input.module.css"

import { randomId } from "~/helpers/general-helpers"

import InputLabel from "./InputLabel"

export type Props = {
  id?: string
  dataTest?: string
  label?: string | React.ReactElement
  autoFocus?: boolean
  name?: string
  placeholder?: string
  autoComplete?: string
  type?: string
  style?: Record<string, any>
  tabIndex?: number
  error?: boolean
  customErrorMessage?: string
  optional?: boolean
  min?: number | string
  onBlur?: (target: any) => void
  onKeyDown?: (target: any) => void
  defaultSelectAll?: boolean
  labelRight?: string | React.ReactElement
  nofocus?: boolean
  height?: number
  fontSize?: number
  disabled?: boolean
  className?: string
  maxLength?: number
  pattern?: string
  onInvalid?: (e: any) => void
  onInput?: (e: any) => void
} & (
  | {
      value: string | number
      onChange: (target: any) => void
      defaultValue?: never
    }
  | {
      defaultValue: string | number
      onChange?: (target: any) => void
      value?: never
    }
  | {
      name: string
      ref: (instance: any) => void
      onChange: (target: any) => void
    } // react-hook-form
)

const Input = (props: Props) => {
  const {
    dataTest,
    customErrorMessage,
    error,
    label,
    optional,
    style,
    defaultSelectAll,
    height,
    fontSize,
    nofocus,
    labelRight,
    className,
    ...rest
  } = props

  const [autoFocus, setAutoFocus] = useState(props.autoFocus)

  const callbackRef = useCallback(
    (inputRef) => {
      const isVisible = inputRef && inputRef.offsetParent !== null
      if (isVisible && autoFocus) {
        inputRef.focus()
      }
      if (isVisible && defaultSelectAll) {
        inputRef.select()
      }
    },
    [autoFocus, defaultSelectAll],
  )

  const onBlur = (e) => {
    setAutoFocus(false)
    if (props.onBlur) {
      props.onBlur(e)
    }
  }

  // Ensure that an id is always set for assistive technologies
  const id = props.id ? props.id : `input-${randomId()}`

  return (
    <div
      className={`${styles.inputWrapper} ${error && styles.error} ${
        nofocus && styles.nofocus
      }`}
    >
      {label && (
        <InputLabel
          label={label}
          optional={optional}
          error={error}
          customErrorMessage={customErrorMessage}
          labelRight={labelRight}
          htmlFor={id}
        />
      )}
      <input
        {...rest}
        id={id}
        data-test={dataTest}
        className={cc([styles.input, className])}
        ref={callbackRef}
        style={{ ...style, height, fontSize }}
        onBlur={onBlur}
        autoComplete={props.autoComplete || "off"}
      />
    </div>
  )
}

export default Input
