/**
 * @generated SignedSource<<a9c0150ba0618a92ce20de09d44cd256>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type accounts_select_column = "account_type" | "allocated_views_enabled" | "allow_future_actuals" | "allow_profile_photos" | "alternative_account_id" | "billing_method" | "business_days" | "currency" | "default_full_time_minutes" | "id" | "integrations" | "last_copied" | "name" | "rate_type" | "references" | "secondary_person_field" | "subscription" | "subscription_customer" | "subscription_entitlements" | "subscription_type" | "timesheet_lock" | "timesheet_lock_method" | "timesheet_notifications" | "timesheets_enabled" | "timesheets_protected" | "use_week_numbers" | "website" | "%future added value";
export type accounts_select_column_accounts_aggregate_bool_exp_bool_and_arguments_columns = "allocated_views_enabled" | "allow_future_actuals" | "allow_profile_photos" | "timesheet_notifications" | "timesheets_enabled" | "timesheets_protected" | "use_week_numbers" | "%future added value";
export type accounts_select_column_accounts_aggregate_bool_exp_bool_or_arguments_columns = "allocated_views_enabled" | "allow_future_actuals" | "allow_profile_photos" | "timesheet_notifications" | "timesheets_enabled" | "timesheets_protected" | "use_week_numbers" | "%future added value";
export type actuals_select_column = "account_id" | "billable_minutes" | "billable_note" | "created_at" | "date_iso" | "id" | "nonbillable_minutes" | "nonbillable_note" | "origin_data" | "person_id" | "phase_id" | "project_id" | "role_id" | "updated_at" | "workstream_id" | "%future added value";
export type assignments_select_column = "account_id" | "active" | "created_at" | "end_date_iso" | "id" | "is_billable" | "is_template" | "minutes_per_day" | "non_working_day" | "note" | "person_id" | "phase_id" | "project_id" | "role_id" | "start_date_iso" | "total_minutes" | "updated_at" | "workstream_id" | "%future added value";
export type assignments_select_column_assignments_aggregate_bool_exp_bool_and_arguments_columns = "active" | "is_billable" | "is_template" | "non_working_day" | "%future added value";
export type assignments_select_column_assignments_aggregate_bool_exp_bool_or_arguments_columns = "active" | "is_billable" | "is_template" | "non_working_day" | "%future added value";
export type clients_select_column = "account_id" | "active" | "created_at" | "id" | "image_key" | "name" | "real_client" | "references" | "updated_at" | "website" | "%future added value";
export type clients_select_column_clients_aggregate_bool_exp_bool_and_arguments_columns = "active" | "real_client" | "%future added value";
export type clients_select_column_clients_aggregate_bool_exp_bool_or_arguments_columns = "active" | "real_client" | "%future added value";
export type contracts_select_column = "account_id" | "created_at" | "employment_type" | "end_date_iso" | "id" | "job_title" | "minutes_per_day" | "person_id" | "role_id" | "rostered_days" | "start_date_iso" | "updated_at" | "%future added value";
export type custom_checkbox_types_select_column = "description" | "filterable_in_planner" | "id" | "model" | "name" | "required" | "show_in_planner" | "sort_order" | "%future added value";
export type custom_checkbox_types_select_column_custom_checkbox_types_aggregate_bool_exp_bool_and_arguments_columns = "filterable_in_planner" | "required" | "show_in_planner" | "%future added value";
export type custom_checkbox_types_select_column_custom_checkbox_types_aggregate_bool_exp_bool_or_arguments_columns = "filterable_in_planner" | "required" | "show_in_planner" | "%future added value";
export type custom_checkbox_values_select_column = "custom_checkbox_type_id" | "id" | "model" | "person_id" | "project_id" | "value" | "%future added value";
export type custom_checkbox_values_select_column_custom_checkbox_values_aggregate_bool_exp_bool_and_arguments_columns = "value" | "%future added value";
export type custom_checkbox_values_select_column_custom_checkbox_values_aggregate_bool_exp_bool_or_arguments_columns = "value" | "%future added value";
export type custom_date_types_select_column = "description" | "filterable_in_planner" | "id" | "model" | "name" | "required" | "show_in_planner" | "sort_order" | "%future added value";
export type custom_date_types_select_column_custom_date_types_aggregate_bool_exp_bool_and_arguments_columns = "filterable_in_planner" | "required" | "show_in_planner" | "%future added value";
export type custom_date_types_select_column_custom_date_types_aggregate_bool_exp_bool_or_arguments_columns = "filterable_in_planner" | "required" | "show_in_planner" | "%future added value";
export type custom_date_values_select_column = "custom_date_type_id" | "id" | "model" | "person_id" | "project_id" | "value" | "%future added value";
export type custom_select_options_select_column = "custom_select_type_id" | "id" | "name" | "%future added value";
export type custom_select_types_select_column = "description" | "filterable_in_planner" | "id" | "model" | "name" | "required" | "show_in_planner" | "single_select" | "sort_order" | "%future added value";
export type custom_select_types_select_column_custom_select_types_aggregate_bool_exp_bool_and_arguments_columns = "filterable_in_planner" | "required" | "show_in_planner" | "single_select" | "%future added value";
export type custom_select_types_select_column_custom_select_types_aggregate_bool_exp_bool_or_arguments_columns = "filterable_in_planner" | "required" | "show_in_planner" | "single_select" | "%future added value";
export type custom_select_values_select_column = "custom_select_option_id" | "custom_select_type_id" | "id" | "model" | "person_id" | "project_id" | "%future added value";
export type custom_text_types_select_column = "description" | "filterable_in_planner" | "id" | "model" | "name" | "required" | "show_in_planner" | "sort_order" | "%future added value";
export type custom_text_types_select_column_custom_text_types_aggregate_bool_exp_bool_and_arguments_columns = "filterable_in_planner" | "required" | "show_in_planner" | "%future added value";
export type custom_text_types_select_column_custom_text_types_aggregate_bool_exp_bool_or_arguments_columns = "filterable_in_planner" | "required" | "show_in_planner" | "%future added value";
export type custom_text_values_select_column = "custom_text_type_id" | "id" | "model" | "person_id" | "project_id" | "value" | "%future added value";
export type ext_person_select_column = "account_id" | "active" | "created_at" | "deleted" | "email" | "ext_team_id" | "external_id" | "first_name" | "id" | "image_key" | "integration_id" | "is_placeholder" | "last_name" | "references" | "remote_id" | "sync" | "tags" | "updated_at" | "%future added value";
export type ext_person_select_column_ext_person_aggregate_bool_exp_bool_and_arguments_columns = "active" | "deleted" | "is_placeholder" | "sync" | "%future added value";
export type ext_person_select_column_ext_person_aggregate_bool_exp_bool_or_arguments_columns = "active" | "deleted" | "is_placeholder" | "sync" | "%future added value";
export type ext_project_select_column = "account_id" | "active" | "confirmed" | "created_at" | "deleted" | "emoji" | "expenses_budget" | "external_id" | "id" | "integration_id" | "is_template" | "name" | "pricing_model" | "priority" | "rate_type" | "references" | "remote_id" | "remote_url" | "sync" | "tags" | "total_budget" | "updated_at" | "%future added value";
export type ext_project_select_column_ext_project_aggregate_bool_exp_bool_and_arguments_columns = "active" | "confirmed" | "deleted" | "is_template" | "sync" | "%future added value";
export type ext_project_select_column_ext_project_aggregate_bool_exp_bool_or_arguments_columns = "active" | "confirmed" | "deleted" | "is_template" | "sync" | "%future added value";
export type holidays_constraint = "holidays_pkey" | "index_unique_holidays_id_account_id" | "%future added value";
export type holidays_groups_constraint = "holidays_groups_pkey" | "index_unique_holidays_groups_id_account_id" | "%future added value";
export type holidays_groups_select_column = "account_id" | "country_code" | "country_name" | "created_at" | "id" | "name" | "region_name" | "updated_at" | "%future added value";
export type holidays_groups_update_column = "name" | "%future added value";
export type holidays_select_column = "account_id" | "created_at" | "date" | "holiday_api_uuid" | "holidays_group_id" | "id" | "name" | "observed" | "type" | "updated_at" | "%future added value";
export type holidays_update_column = "date" | "name" | "observed" | "type" | "%future added value";
export type invitation_people_managers_select_column = "account_id" | "created_at" | "id" | "invitation_id" | "person_id" | "updated_at" | "%future added value";
export type invitation_project_managers_select_column = "account_id" | "created_at" | "id" | "invitation_id" | "project_id" | "updated_at" | "%future added value";
export type invitations_select_column = "account_id" | "add_all_people_to_projects_permission" | "created_at" | "email" | "expires_at" | "fin_permission" | "from_user_id" | "id" | "manage_account_permission" | "manage_people_permission" | "manage_projects_permission" | "updated_at" | "user_type" | "uuid" | "view_planner_permission" | "%future added value";
export type invitations_select_column_invitations_aggregate_bool_exp_bool_and_arguments_columns = "add_all_people_to_projects_permission" | "manage_account_permission" | "view_planner_permission" | "%future added value";
export type invitations_select_column_invitations_aggregate_bool_exp_bool_or_arguments_columns = "add_all_people_to_projects_permission" | "manage_account_permission" | "view_planner_permission" | "%future added value";
export type milestones_select_column = "account_id" | "created_at" | "date" | "icon" | "id" | "note" | "project_id" | "title" | "updated_at" | "%future added value";
export type notes_select_column = "account_id" | "created_at" | "created_by" | "creator_email" | "id" | "note" | "project_id" | "updated_at" | "user_id" | "%future added value";
export type other_costs_select_column = "account_id" | "charge" | "cost" | "date" | "id" | "name" | "project_id" | "%future added value";
export type people_notes_select_column = "created_at" | "created_by" | "creator_email" | "id" | "note" | "person_id" | "updated_at" | "user_id" | "%future added value";
export type people_select_column = "account_id" | "active" | "created_at" | "email" | "first_name" | "id" | "image_key" | "is_placeholder" | "last_name" | "placeholder_count" | "references" | "tags" | "team_id" | "updated_at" | "%future added value";
export type people_select_column_people_aggregate_bool_exp_bool_and_arguments_columns = "active" | "is_placeholder" | "%future added value";
export type people_select_column_people_aggregate_bool_exp_bool_or_arguments_columns = "active" | "is_placeholder" | "%future added value";
export type phases_select_column = "account_id" | "color" | "created_at" | "end_date" | "id" | "name" | "project_id" | "start_date" | "updated_at" | "%future added value";
export type project_members_select_column = "account_id" | "created_at" | "id" | "is_placeholder" | "person_id" | "project_id" | "role_id" | "updated_at" | "workstream_id" | "%future added value";
export type project_members_select_column_project_members_aggregate_bool_exp_bool_and_arguments_columns = "is_placeholder" | "%future added value";
export type project_members_select_column_project_members_aggregate_bool_exp_bool_or_arguments_columns = "is_placeholder" | "%future added value";
export type project_rates_select_column = "account_id" | "created_at" | "id" | "project_id" | "role_id" | "updated_at" | "%future added value";
export type project_roles_select_column = "account_id" | "created_at" | "estimated_minutes" | "id" | "project_id" | "role_id" | "updated_at" | "%future added value";
export type projects_select_column = "account_id" | "active" | "client_id" | "confirmed" | "created_at" | "emoji" | "expenses_budget" | "id" | "is_template" | "name" | "pricing_model" | "priority" | "rate_card_id" | "rate_type" | "references" | "team_id" | "timesheet_lock_last_updated_by" | "timesheet_lock_status" | "timesheet_lock_status_updated_at" | "timesheet_locked_to" | "updated_at" | "%future added value";
export type projects_select_column_projects_aggregate_bool_exp_bool_and_arguments_columns = "active" | "confirmed" | "is_template" | "%future added value";
export type projects_select_column_projects_aggregate_bool_exp_bool_or_arguments_columns = "active" | "confirmed" | "is_template" | "%future added value";
export type rate_cards_select_column = "account_id" | "active" | "blended_rate" | "blended_rate_card" | "created_at" | "description" | "id" | "name" | "rate_type" | "references" | "updated_at" | "%future added value";
export type rate_cards_select_column_rate_cards_aggregate_bool_exp_bool_and_arguments_columns = "active" | "blended_rate_card" | "%future added value";
export type rate_cards_select_column_rate_cards_aggregate_bool_exp_bool_or_arguments_columns = "active" | "blended_rate_card" | "%future added value";
export type role_charge_out_rates_select_column = "account_id" | "rate_card_id" | "role_id" | "%future added value";
export type roles_select_column = "account_id" | "active" | "created_at" | "id" | "name" | "references" | "updated_at" | "%future added value";
export type roles_select_column_roles_aggregate_bool_exp_bool_and_arguments_columns = "active" | "%future added value";
export type roles_select_column_roles_aggregate_bool_exp_bool_or_arguments_columns = "active" | "%future added value";
export type skills_select_column = "account_id" | "id" | "name" | "%future added value";
export type tags_select_column = "account_id" | "archived" | "created_at" | "id" | "model" | "name" | "updated_at" | "%future added value";
export type tags_select_column_tags_aggregate_bool_exp_bool_and_arguments_columns = "archived" | "%future added value";
export type tags_select_column_tags_aggregate_bool_exp_bool_or_arguments_columns = "archived" | "%future added value";
export type teams_select_column = "account_id" | "created_at" | "id" | "name" | "updated_at" | "%future added value";
export type time_offs_select_column = "account_id" | "created_at" | "end_date_iso" | "holiday_id" | "id" | "leave_type" | "minutes_per_day" | "note" | "person_id" | "start_date_iso" | "updated_at" | "%future added value";
export type type_schedule_totals_roles_select_column = "project_id" | "role_id" | "sum_billable_minutes" | "sum_nonbillable_minutes" | "%future added value";
export type users_select_column = "account_id" | "created_at" | "current_sign_in_at" | "email" | "favourite_people" | "favourite_projects" | "first_name" | "id" | "image_key" | "last_name" | "novu_subscriber_id" | "permissions" | "references" | "role_name" | "updated_at" | "view_id" | "viewed_welcome" | "%future added value";
export type users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns = "viewed_welcome" | "%future added value";
export type users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns = "viewed_welcome" | "%future added value";
export type views_select_column = "description" | "id" | "name" | "people_filters" | "project_filters" | "%future added value";
export type workstreams_select_column = "archived" | "id" | "name" | "%future added value";
export type workstreams_select_column_workstreams_aggregate_bool_exp_bool_and_arguments_columns = "archived" | "%future added value";
export type workstreams_select_column_workstreams_aggregate_bool_exp_bool_or_arguments_columns = "archived" | "%future added value";
export type holidays_groups_insert_input = {
  country_code?: string | null | undefined;
  country_name?: string | null | undefined;
  holidays?: holidays_arr_rel_insert_input | null | undefined;
  name?: string | null | undefined;
  region_name?: string | null | undefined;
};
export type holidays_arr_rel_insert_input = {
  data: ReadonlyArray<holidays_insert_input>;
  on_conflict?: holidays_on_conflict | null | undefined;
};
export type holidays_insert_input = {
  date?: any | null | undefined;
  holiday_api_uuid?: any | null | undefined;
  holidays_group?: holidays_groups_obj_rel_insert_input | null | undefined;
  holidays_group_id?: number | null | undefined;
  id?: number | null | undefined;
  name?: string | null | undefined;
  observed?: any | null | undefined;
  type?: string | null | undefined;
};
export type holidays_groups_obj_rel_insert_input = {
  data: holidays_groups_insert_input;
  on_conflict?: holidays_groups_on_conflict | null | undefined;
};
export type holidays_groups_on_conflict = {
  constraint: holidays_groups_constraint;
  update_columns?: ReadonlyArray<holidays_groups_update_column>;
  where?: holidays_groups_bool_exp | null | undefined;
};
export type holidays_groups_bool_exp = {
  _and?: ReadonlyArray<holidays_groups_bool_exp> | null | undefined;
  _not?: holidays_groups_bool_exp | null | undefined;
  _or?: ReadonlyArray<holidays_groups_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  country_code?: String_comparison_exp | null | undefined;
  country_name?: String_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  holidays?: holidays_bool_exp | null | undefined;
  holidays_aggregate?: holidays_aggregate_bool_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  people?: people_bool_exp | null | undefined;
  people_aggregate?: people_aggregate_bool_exp | null | undefined;
  region_name?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type accounts_bool_exp = {
  _and?: ReadonlyArray<accounts_bool_exp> | null | undefined;
  _not?: accounts_bool_exp | null | undefined;
  _or?: ReadonlyArray<accounts_bool_exp> | null | undefined;
  account_type?: String_comparison_exp | null | undefined;
  accounts?: accounts_bool_exp | null | undefined;
  accounts_aggregate?: accounts_aggregate_bool_exp | null | undefined;
  allocated_views_enabled?: Boolean_comparison_exp | null | undefined;
  allow_future_actuals?: Boolean_comparison_exp | null | undefined;
  allow_profile_photos?: Boolean_comparison_exp | null | undefined;
  alternative_account?: accounts_bool_exp | null | undefined;
  alternative_account_id?: Int_comparison_exp | null | undefined;
  billing_method?: String_comparison_exp | null | undefined;
  business_days?: String_array_comparison_exp | null | undefined;
  cancel_reasons?: cancel_reasons_bool_exp | null | undefined;
  clients?: clients_bool_exp | null | undefined;
  clients_aggregate?: clients_aggregate_bool_exp | null | undefined;
  competencies?: competencies_bool_exp | null | undefined;
  currency?: String_comparison_exp | null | undefined;
  custom_checkbox_types?: custom_checkbox_types_bool_exp | null | undefined;
  custom_checkbox_types_aggregate?: custom_checkbox_types_aggregate_bool_exp | null | undefined;
  custom_date_types?: custom_date_types_bool_exp | null | undefined;
  custom_date_types_aggregate?: custom_date_types_aggregate_bool_exp | null | undefined;
  custom_select_types?: custom_select_types_bool_exp | null | undefined;
  custom_select_types_aggregate?: custom_select_types_aggregate_bool_exp | null | undefined;
  custom_text_types?: custom_text_types_bool_exp | null | undefined;
  custom_text_types_aggregate?: custom_text_types_aggregate_bool_exp | null | undefined;
  default_full_time_minutes?: Int_comparison_exp | null | undefined;
  ext_people?: ext_person_bool_exp | null | undefined;
  ext_people_aggregate?: ext_person_aggregate_bool_exp | null | undefined;
  ext_project_members?: ext_project_member_bool_exp | null | undefined;
  ext_projects?: ext_project_bool_exp | null | undefined;
  ext_projects_aggregate?: ext_project_aggregate_bool_exp | null | undefined;
  features_accounts?: features_accounts_bool_exp | null | undefined;
  holidays?: holidays_bool_exp | null | undefined;
  holidays_aggregate?: holidays_aggregate_bool_exp | null | undefined;
  holidays_groups?: holidays_groups_bool_exp | null | undefined;
  holidays_groups_aggregate?: holidays_groups_aggregate_bool_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  integrations?: jsonb_comparison_exp | null | undefined;
  integrationsV2?: integration_bool_exp | null | undefined;
  invitations?: invitations_bool_exp | null | undefined;
  invitations_aggregate?: invitations_aggregate_bool_exp | null | undefined;
  last_copied?: timestamp_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  people?: people_bool_exp | null | undefined;
  people_aggregate?: people_aggregate_bool_exp | null | undefined;
  person_requests?: person_requests_bool_exp | null | undefined;
  project_members?: project_members_bool_exp | null | undefined;
  project_members_aggregate?: project_members_aggregate_bool_exp | null | undefined;
  projects?: projects_bool_exp | null | undefined;
  projects_aggregate?: projects_aggregate_bool_exp | null | undefined;
  rate_cards?: rate_cards_bool_exp | null | undefined;
  rate_cards_aggregate?: rate_cards_aggregate_bool_exp | null | undefined;
  rate_type?: String_comparison_exp | null | undefined;
  references?: jsonb_comparison_exp | null | undefined;
  roles?: roles_bool_exp | null | undefined;
  roles_aggregate?: roles_aggregate_bool_exp | null | undefined;
  saved_reports?: saved_reports_bool_exp | null | undefined;
  secondary_person_field?: String_comparison_exp | null | undefined;
  skills?: skills_bool_exp | null | undefined;
  skills_aggregate?: skills_aggregate_bool_exp | null | undefined;
  subscription?: jsonb_comparison_exp | null | undefined;
  subscription_customer?: jsonb_comparison_exp | null | undefined;
  subscription_entitlements?: jsonb_comparison_exp | null | undefined;
  subscription_type?: String_comparison_exp | null | undefined;
  tags?: tags_bool_exp | null | undefined;
  tags_aggregate?: tags_aggregate_bool_exp | null | undefined;
  teams?: teams_bool_exp | null | undefined;
  teams_aggregate?: teams_aggregate_bool_exp | null | undefined;
  timesheet_lock?: Int_comparison_exp | null | undefined;
  timesheet_lock_method?: String_comparison_exp | null | undefined;
  timesheet_notifications?: Boolean_comparison_exp | null | undefined;
  timesheets_enabled?: Boolean_comparison_exp | null | undefined;
  timesheets_protected?: Boolean_comparison_exp | null | undefined;
  use_week_numbers?: Boolean_comparison_exp | null | undefined;
  user_accounts?: user_accounts_bool_exp | null | undefined;
  users?: users_bool_exp | null | undefined;
  users_aggregate?: users_aggregate_bool_exp | null | undefined;
  views?: views_bool_exp | null | undefined;
  views_aggregate?: views_aggregate_bool_exp | null | undefined;
  website?: String_comparison_exp | null | undefined;
  workstreams?: workstreams_bool_exp | null | undefined;
  workstreams_aggregate?: workstreams_aggregate_bool_exp | null | undefined;
};
export type String_comparison_exp = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _ilike?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _iregex?: string | null | undefined;
  _is_null?: boolean | null | undefined;
  _like?: string | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
  _nilike?: string | null | undefined;
  _nin?: ReadonlyArray<string> | null | undefined;
  _niregex?: string | null | undefined;
  _nlike?: string | null | undefined;
  _nregex?: string | null | undefined;
  _nsimilar?: string | null | undefined;
  _regex?: string | null | undefined;
  _similar?: string | null | undefined;
};
export type accounts_aggregate_bool_exp = {
  bool_and?: accounts_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: accounts_aggregate_bool_exp_bool_or | null | undefined;
  count?: accounts_aggregate_bool_exp_count | null | undefined;
};
export type accounts_aggregate_bool_exp_bool_and = {
  arguments: accounts_select_column_accounts_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: accounts_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type Boolean_comparison_exp = {
  _eq?: boolean | null | undefined;
  _gt?: boolean | null | undefined;
  _gte?: boolean | null | undefined;
  _in?: ReadonlyArray<boolean> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: boolean | null | undefined;
  _lte?: boolean | null | undefined;
  _neq?: boolean | null | undefined;
  _nin?: ReadonlyArray<boolean> | null | undefined;
};
export type accounts_aggregate_bool_exp_bool_or = {
  arguments: accounts_select_column_accounts_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: accounts_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type accounts_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<accounts_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: accounts_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type Int_comparison_exp = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
  _nin?: ReadonlyArray<number> | null | undefined;
};
export type String_array_comparison_exp = {
  _contained_in?: ReadonlyArray<string> | null | undefined;
  _contains?: ReadonlyArray<string> | null | undefined;
  _eq?: ReadonlyArray<string> | null | undefined;
  _gt?: ReadonlyArray<string> | null | undefined;
  _gte?: ReadonlyArray<string> | null | undefined;
  _in?: ReadonlyArray<ReadonlyArray<string>> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: ReadonlyArray<string> | null | undefined;
  _lte?: ReadonlyArray<string> | null | undefined;
  _neq?: ReadonlyArray<string> | null | undefined;
  _nin?: ReadonlyArray<ReadonlyArray<string>> | null | undefined;
};
export type cancel_reasons_bool_exp = {
  _and?: ReadonlyArray<cancel_reasons_bool_exp> | null | undefined;
  _not?: cancel_reasons_bool_exp | null | undefined;
  _or?: ReadonlyArray<cancel_reasons_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  account_name?: String_comparison_exp | null | undefined;
};
export type clients_bool_exp = {
  _and?: ReadonlyArray<clients_bool_exp> | null | undefined;
  _not?: clients_bool_exp | null | undefined;
  _or?: ReadonlyArray<clients_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  archivable?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  deletable?: Boolean_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  image_key?: String_comparison_exp | null | undefined;
  internal?: Boolean_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  projects?: projects_bool_exp | null | undefined;
  projects_aggregate?: projects_aggregate_bool_exp | null | undefined;
  real_client?: Boolean_comparison_exp | null | undefined;
  references?: jsonb_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  website?: String_comparison_exp | null | undefined;
};
export type timestamp_comparison_exp = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
  _nin?: ReadonlyArray<string> | null | undefined;
};
export type projects_bool_exp = {
  _and?: ReadonlyArray<projects_bool_exp> | null | undefined;
  _not?: projects_bool_exp | null | undefined;
  _or?: ReadonlyArray<projects_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  actuals?: actuals_bool_exp | null | undefined;
  actuals_aggregate?: actuals_aggregate_bool_exp | null | undefined;
  archivable?: Boolean_comparison_exp | null | undefined;
  assignments?: assignments_bool_exp | null | undefined;
  assignments_aggregate?: assignments_aggregate_bool_exp | null | undefined;
  calc_end_date?: String_comparison_exp | null | undefined;
  calc_start_date?: String_comparison_exp | null | undefined;
  client?: clients_bool_exp | null | undefined;
  client_id?: Int_comparison_exp | null | undefined;
  confirmed?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  custom_checkbox_values?: custom_checkbox_values_bool_exp | null | undefined;
  custom_checkbox_values_aggregate?: custom_checkbox_values_aggregate_bool_exp | null | undefined;
  custom_date_values?: custom_date_values_bool_exp | null | undefined;
  custom_date_values_aggregate?: custom_date_values_aggregate_bool_exp | null | undefined;
  custom_select_values?: custom_select_values_bool_exp | null | undefined;
  custom_select_values_aggregate?: custom_select_values_aggregate_bool_exp | null | undefined;
  custom_text_values?: custom_text_values_bool_exp | null | undefined;
  custom_text_values_aggregate?: custom_text_values_aggregate_bool_exp | null | undefined;
  emoji?: String_comparison_exp | null | undefined;
  expenses_budget?: Int_comparison_exp | null | undefined;
  ext_project_links?: ext_project_link_bool_exp | null | undefined;
  has_actuals?: Boolean_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  is_template?: Boolean_comparison_exp | null | undefined;
  links?: project_links_bool_exp | null | undefined;
  managers?: project_managers_bool_exp | null | undefined;
  members?: project_members_bool_exp | null | undefined;
  members_aggregate?: project_members_aggregate_bool_exp | null | undefined;
  milestones?: milestones_bool_exp | null | undefined;
  milestones_aggregate?: milestones_aggregate_bool_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  notes?: notes_bool_exp | null | undefined;
  notes_aggregate?: notes_aggregate_bool_exp | null | undefined;
  other_costs?: other_costs_bool_exp | null | undefined;
  other_costs_aggregate?: other_costs_aggregate_bool_exp | null | undefined;
  person_requests?: person_requests_bool_exp | null | undefined;
  phases?: phases_bool_exp | null | undefined;
  phases_aggregate?: phases_aggregate_bool_exp | null | undefined;
  pricing_model?: String_comparison_exp | null | undefined;
  pricing_model_readable?: String_comparison_exp | null | undefined;
  priority?: String_comparison_exp | null | undefined;
  project_rates?: project_rates_bool_exp | null | undefined;
  project_rates_aggregate?: project_rates_aggregate_bool_exp | null | undefined;
  project_roles?: project_roles_bool_exp | null | undefined;
  project_roles_aggregate?: project_roles_aggregate_bool_exp | null | undefined;
  project_tags?: project_tags_bool_exp | null | undefined;
  project_workstreams?: project_workstreams_bool_exp | null | undefined;
  rate_card?: rate_cards_bool_exp | null | undefined;
  rate_card_id?: Int_comparison_exp | null | undefined;
  rate_type?: String_comparison_exp | null | undefined;
  references?: jsonb_comparison_exp | null | undefined;
  schedule_totals?: type_schedule_totals_bool_exp | null | undefined;
  schedule_totals_roles?: type_schedule_totals_roles_bool_exp | null | undefined;
  summary?: v_summary_total_bool_exp | null | undefined;
  tags_computed?: jsonb_comparison_exp | null | undefined;
  team?: teams_bool_exp | null | undefined;
  team_id?: Int_comparison_exp | null | undefined;
  timesheet_lock_last_updated_by?: String_comparison_exp | null | undefined;
  timesheet_lock_status?: timesheet_lock_status_comparison_exp | null | undefined;
  timesheet_lock_status_updated_at?: timestamp_comparison_exp | null | undefined;
  timesheet_locked_to?: date_comparison_exp | null | undefined;
  total_budget_private?: numeric_comparison_exp | null | undefined;
  type_schedule_totals?: type_schedule_totals_bool_exp | null | undefined;
  type_schedule_totals_roles?: type_schedule_totals_roles_bool_exp | null | undefined;
  type_schedule_totals_roles_aggregate?: type_schedule_totals_roles_aggregate_bool_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  workstreams_computed?: jsonb_comparison_exp | null | undefined;
};
export type actuals_bool_exp = {
  _and?: ReadonlyArray<actuals_bool_exp> | null | undefined;
  _not?: actuals_bool_exp | null | undefined;
  _or?: ReadonlyArray<actuals_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  billable_minutes?: Int_comparison_exp | null | undefined;
  billable_note?: String_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  date_iso?: date_comparison_exp | null | undefined;
  date_runn?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  nonbillable_minutes?: Int_comparison_exp | null | undefined;
  nonbillable_note?: String_comparison_exp | null | undefined;
  origin_data?: jsonb_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  phase?: phases_bool_exp | null | undefined;
  phase_id?: Int_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  role?: roles_bool_exp | null | undefined;
  role_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  workstream_id?: Int_comparison_exp | null | undefined;
};
export type date_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type jsonb_comparison_exp = {
  _cast?: jsonb_cast_exp | null | undefined;
  _contained_in?: any | null | undefined;
  _contains?: any | null | undefined;
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _has_key?: string | null | undefined;
  _has_keys_all?: ReadonlyArray<string> | null | undefined;
  _has_keys_any?: ReadonlyArray<string> | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type jsonb_cast_exp = {
  String?: String_comparison_exp | null | undefined;
};
export type people_bool_exp = {
  _and?: ReadonlyArray<people_bool_exp> | null | undefined;
  _not?: people_bool_exp | null | undefined;
  _or?: ReadonlyArray<people_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  actuals?: actuals_bool_exp | null | undefined;
  actuals_aggregate?: actuals_aggregate_bool_exp | null | undefined;
  archivable?: Boolean_comparison_exp | null | undefined;
  assignments?: assignments_bool_exp | null | undefined;
  assignments_aggregate?: assignments_aggregate_bool_exp | null | undefined;
  competencies?: competencies_bool_exp | null | undefined;
  contracts?: contracts_bool_exp | null | undefined;
  contracts_aggregate?: contracts_aggregate_bool_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  current_contract?: contracts_bool_exp | null | undefined;
  current_role?: roles_bool_exp | null | undefined;
  custom_checkbox_values?: custom_checkbox_values_bool_exp | null | undefined;
  custom_checkbox_values_aggregate?: custom_checkbox_values_aggregate_bool_exp | null | undefined;
  custom_date_values?: custom_date_values_bool_exp | null | undefined;
  custom_date_values_aggregate?: custom_date_values_aggregate_bool_exp | null | undefined;
  custom_select_values?: custom_select_values_bool_exp | null | undefined;
  custom_select_values_aggregate?: custom_select_values_aggregate_bool_exp | null | undefined;
  custom_text_values?: custom_text_values_bool_exp | null | undefined;
  custom_text_values_aggregate?: custom_text_values_aggregate_bool_exp | null | undefined;
  email?: String_comparison_exp | null | undefined;
  ext_person_links?: ext_person_link_bool_exp | null | undefined;
  first_name?: String_comparison_exp | null | undefined;
  holidays_group?: holidays_groups_bool_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  image_key?: String_comparison_exp | null | undefined;
  is_placeholder?: Boolean_comparison_exp | null | undefined;
  last_name?: String_comparison_exp | null | undefined;
  links?: people_links_bool_exp | null | undefined;
  managers?: people_managers_bool_exp | null | undefined;
  people_notes?: people_notes_bool_exp | null | undefined;
  people_notes_aggregate?: people_notes_aggregate_bool_exp | null | undefined;
  person_requests?: person_requests_bool_exp | null | undefined;
  placeholder_count?: Int_comparison_exp | null | undefined;
  placeholder_suggestions?: placeholder_suggestions_bool_exp | null | undefined;
  project_memberships?: project_members_bool_exp | null | undefined;
  project_memberships_aggregate?: project_members_aggregate_bool_exp | null | undefined;
  references?: jsonb_comparison_exp | null | undefined;
  tags?: jsonb_comparison_exp | null | undefined;
  team?: teams_bool_exp | null | undefined;
  team_id?: Int_comparison_exp | null | undefined;
  time_offs?: time_offs_bool_exp | null | undefined;
  time_offs_aggregate?: time_offs_aggregate_bool_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type actuals_aggregate_bool_exp = {
  count?: actuals_aggregate_bool_exp_count | null | undefined;
};
export type actuals_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<actuals_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: actuals_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type assignments_bool_exp = {
  _and?: ReadonlyArray<assignments_bool_exp> | null | undefined;
  _not?: assignments_bool_exp | null | undefined;
  _or?: ReadonlyArray<assignments_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  end_date_iso?: date_comparison_exp | null | undefined;
  end_date_runn?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  is_billable?: Boolean_comparison_exp | null | undefined;
  is_template?: Boolean_comparison_exp | null | undefined;
  minutes_per_day?: Int_comparison_exp | null | undefined;
  non_working_day?: Boolean_comparison_exp | null | undefined;
  note?: String_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  phase?: phases_bool_exp | null | undefined;
  phase_id?: Int_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  role?: roles_bool_exp | null | undefined;
  role_id?: Int_comparison_exp | null | undefined;
  start_date_iso?: date_comparison_exp | null | undefined;
  start_date_runn?: String_comparison_exp | null | undefined;
  total_minutes?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  workstream_id?: Int_comparison_exp | null | undefined;
};
export type phases_bool_exp = {
  _and?: ReadonlyArray<phases_bool_exp> | null | undefined;
  _not?: phases_bool_exp | null | undefined;
  _or?: ReadonlyArray<phases_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  actuals?: actuals_bool_exp | null | undefined;
  actuals_aggregate?: actuals_aggregate_bool_exp | null | undefined;
  assignments?: assignments_bool_exp | null | undefined;
  assignments_aggregate?: assignments_aggregate_bool_exp | null | undefined;
  color?: String_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  end_date?: date_comparison_exp | null | undefined;
  end_date_runn?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  start_date?: date_comparison_exp | null | undefined;
  start_date_runn?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type assignments_aggregate_bool_exp = {
  bool_and?: assignments_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: assignments_aggregate_bool_exp_bool_or | null | undefined;
  count?: assignments_aggregate_bool_exp_count | null | undefined;
};
export type assignments_aggregate_bool_exp_bool_and = {
  arguments: assignments_select_column_assignments_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: assignments_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type assignments_aggregate_bool_exp_bool_or = {
  arguments: assignments_select_column_assignments_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: assignments_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type assignments_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<assignments_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: assignments_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type roles_bool_exp = {
  _and?: ReadonlyArray<roles_bool_exp> | null | undefined;
  _not?: roles_bool_exp | null | undefined;
  _or?: ReadonlyArray<roles_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  actuals?: actuals_bool_exp | null | undefined;
  actuals_aggregate?: actuals_aggregate_bool_exp | null | undefined;
  assignments?: assignments_bool_exp | null | undefined;
  assignments_aggregate?: assignments_aggregate_bool_exp | null | undefined;
  contracts?: contracts_bool_exp | null | undefined;
  contracts_aggregate?: contracts_aggregate_bool_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  default_hour_cost_private?: numeric_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  project_members?: project_members_bool_exp | null | undefined;
  project_members_aggregate?: project_members_aggregate_bool_exp | null | undefined;
  project_rates?: project_rates_bool_exp | null | undefined;
  project_rates_aggregate?: project_rates_aggregate_bool_exp | null | undefined;
  references?: jsonb_comparison_exp | null | undefined;
  role_charge_out_rates?: role_charge_out_rates_bool_exp | null | undefined;
  role_charge_out_rates_aggregate?: role_charge_out_rates_aggregate_bool_exp | null | undefined;
  type_schedule_totals_roles?: type_schedule_totals_roles_bool_exp | null | undefined;
  type_schedule_totals_roles_aggregate?: type_schedule_totals_roles_aggregate_bool_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type contracts_bool_exp = {
  _and?: ReadonlyArray<contracts_bool_exp> | null | undefined;
  _not?: contracts_bool_exp | null | undefined;
  _or?: ReadonlyArray<contracts_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  cost_padded_private?: numeric_comparison_exp | null | undefined;
  cost_private?: numeric_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  employment_type?: String_comparison_exp | null | undefined;
  end_date_iso?: date_comparison_exp | null | undefined;
  end_date_runn?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  job_title?: String_comparison_exp | null | undefined;
  minutes_per_day?: Int_comparison_exp | null | undefined;
  permanent?: Boolean_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  role?: roles_bool_exp | null | undefined;
  role_id?: Int_comparison_exp | null | undefined;
  rostered_days?: Int_array_comparison_exp | null | undefined;
  start_date_iso?: date_comparison_exp | null | undefined;
  start_date_runn?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type numeric_comparison_exp = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
  _nin?: ReadonlyArray<number> | null | undefined;
};
export type Int_array_comparison_exp = {
  _contained_in?: ReadonlyArray<number> | null | undefined;
  _contains?: ReadonlyArray<number> | null | undefined;
  _eq?: ReadonlyArray<number> | null | undefined;
  _gt?: ReadonlyArray<number> | null | undefined;
  _gte?: ReadonlyArray<number> | null | undefined;
  _in?: ReadonlyArray<ReadonlyArray<number>> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: ReadonlyArray<number> | null | undefined;
  _lte?: ReadonlyArray<number> | null | undefined;
  _neq?: ReadonlyArray<number> | null | undefined;
  _nin?: ReadonlyArray<ReadonlyArray<number>> | null | undefined;
};
export type contracts_aggregate_bool_exp = {
  count?: contracts_aggregate_bool_exp_count | null | undefined;
};
export type contracts_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<contracts_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: contracts_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type project_members_bool_exp = {
  _and?: ReadonlyArray<project_members_bool_exp> | null | undefined;
  _not?: project_members_bool_exp | null | undefined;
  _or?: ReadonlyArray<project_members_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  actuals_count?: Int_comparison_exp | null | undefined;
  assignments_count?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  has_actuals?: Boolean_comparison_exp | null | undefined;
  has_assignments?: Boolean_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  is_placeholder?: Boolean_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  role?: roles_bool_exp | null | undefined;
  role_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  workstream?: workstreams_bool_exp | null | undefined;
  workstream_id?: Int_comparison_exp | null | undefined;
};
export type workstreams_bool_exp = {
  _and?: ReadonlyArray<workstreams_bool_exp> | null | undefined;
  _not?: workstreams_bool_exp | null | undefined;
  _or?: ReadonlyArray<workstreams_bool_exp> | null | undefined;
  archived?: Boolean_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  project_workstreams?: project_workstreams_bool_exp | null | undefined;
  workstreams_account?: accounts_bool_exp | null | undefined;
};
export type project_workstreams_bool_exp = {
  _and?: ReadonlyArray<project_workstreams_bool_exp> | null | undefined;
  _not?: project_workstreams_bool_exp | null | undefined;
  _or?: ReadonlyArray<project_workstreams_bool_exp> | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  workstream?: workstreams_bool_exp | null | undefined;
  workstream_id?: Int_comparison_exp | null | undefined;
};
export type project_members_aggregate_bool_exp = {
  bool_and?: project_members_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: project_members_aggregate_bool_exp_bool_or | null | undefined;
  count?: project_members_aggregate_bool_exp_count | null | undefined;
};
export type project_members_aggregate_bool_exp_bool_and = {
  arguments: project_members_select_column_project_members_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: project_members_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type project_members_aggregate_bool_exp_bool_or = {
  arguments: project_members_select_column_project_members_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: project_members_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type project_members_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<project_members_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: project_members_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type project_rates_bool_exp = {
  _and?: ReadonlyArray<project_rates_bool_exp> | null | undefined;
  _not?: project_rates_bool_exp | null | undefined;
  _or?: ReadonlyArray<project_rates_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  rate_private?: numeric_comparison_exp | null | undefined;
  role?: roles_bool_exp | null | undefined;
  role_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type project_rates_aggregate_bool_exp = {
  count?: project_rates_aggregate_bool_exp_count | null | undefined;
};
export type project_rates_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<project_rates_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: project_rates_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type role_charge_out_rates_bool_exp = {
  _and?: ReadonlyArray<role_charge_out_rates_bool_exp> | null | undefined;
  _not?: role_charge_out_rates_bool_exp | null | undefined;
  _or?: ReadonlyArray<role_charge_out_rates_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  charge_out_rate_private?: numeric_comparison_exp | null | undefined;
  rate_card?: rate_cards_bool_exp | null | undefined;
  rate_card_id?: Int_comparison_exp | null | undefined;
  role?: roles_bool_exp | null | undefined;
  role_id?: Int_comparison_exp | null | undefined;
};
export type rate_cards_bool_exp = {
  _and?: ReadonlyArray<rate_cards_bool_exp> | null | undefined;
  _not?: rate_cards_bool_exp | null | undefined;
  _or?: ReadonlyArray<rate_cards_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  archivable?: Boolean_comparison_exp | null | undefined;
  blended_rate?: numeric_comparison_exp | null | undefined;
  blended_rate_card?: Boolean_comparison_exp | null | undefined;
  blended_rate_private?: numeric_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  description?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  projects?: projects_bool_exp | null | undefined;
  projects_aggregate?: projects_aggregate_bool_exp | null | undefined;
  rate_type?: String_comparison_exp | null | undefined;
  references?: jsonb_comparison_exp | null | undefined;
  role_charge_out_rates?: role_charge_out_rates_bool_exp | null | undefined;
  role_charge_out_rates_aggregate?: role_charge_out_rates_aggregate_bool_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type projects_aggregate_bool_exp = {
  bool_and?: projects_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: projects_aggregate_bool_exp_bool_or | null | undefined;
  count?: projects_aggregate_bool_exp_count | null | undefined;
};
export type projects_aggregate_bool_exp_bool_and = {
  arguments: projects_select_column_projects_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: projects_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type projects_aggregate_bool_exp_bool_or = {
  arguments: projects_select_column_projects_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: projects_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type projects_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<projects_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: projects_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type role_charge_out_rates_aggregate_bool_exp = {
  count?: role_charge_out_rates_aggregate_bool_exp_count | null | undefined;
};
export type role_charge_out_rates_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<role_charge_out_rates_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: role_charge_out_rates_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type type_schedule_totals_roles_bool_exp = {
  _and?: ReadonlyArray<type_schedule_totals_roles_bool_exp> | null | undefined;
  _not?: type_schedule_totals_roles_bool_exp | null | undefined;
  _or?: ReadonlyArray<type_schedule_totals_roles_bool_exp> | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  role?: roles_bool_exp | null | undefined;
  role_id?: Int_comparison_exp | null | undefined;
  sum_billable_minutes?: Int_comparison_exp | null | undefined;
  sum_nonbillable_minutes?: Int_comparison_exp | null | undefined;
};
export type type_schedule_totals_roles_aggregate_bool_exp = {
  count?: type_schedule_totals_roles_aggregate_bool_exp_count | null | undefined;
};
export type type_schedule_totals_roles_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<type_schedule_totals_roles_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: type_schedule_totals_roles_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type competencies_bool_exp = {
  _and?: ReadonlyArray<competencies_bool_exp> | null | undefined;
  _not?: competencies_bool_exp | null | undefined;
  _or?: ReadonlyArray<competencies_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  level?: Int_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  skill?: skills_bool_exp | null | undefined;
  skill_id?: Int_comparison_exp | null | undefined;
};
export type skills_bool_exp = {
  _and?: ReadonlyArray<skills_bool_exp> | null | undefined;
  _not?: skills_bool_exp | null | undefined;
  _or?: ReadonlyArray<skills_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
};
export type custom_checkbox_values_bool_exp = {
  _and?: ReadonlyArray<custom_checkbox_values_bool_exp> | null | undefined;
  _not?: custom_checkbox_values_bool_exp | null | undefined;
  _or?: ReadonlyArray<custom_checkbox_values_bool_exp> | null | undefined;
  custom_checkbox_type_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: custom_model_comparison_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  value?: Boolean_comparison_exp | null | undefined;
};
export type custom_model_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type custom_checkbox_values_aggregate_bool_exp = {
  bool_and?: custom_checkbox_values_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: custom_checkbox_values_aggregate_bool_exp_bool_or | null | undefined;
  count?: custom_checkbox_values_aggregate_bool_exp_count | null | undefined;
};
export type custom_checkbox_values_aggregate_bool_exp_bool_and = {
  arguments: custom_checkbox_values_select_column_custom_checkbox_values_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_checkbox_values_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_checkbox_values_aggregate_bool_exp_bool_or = {
  arguments: custom_checkbox_values_select_column_custom_checkbox_values_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_checkbox_values_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_checkbox_values_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<custom_checkbox_values_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: custom_checkbox_values_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type custom_date_values_bool_exp = {
  _and?: ReadonlyArray<custom_date_values_bool_exp> | null | undefined;
  _not?: custom_date_values_bool_exp | null | undefined;
  _or?: ReadonlyArray<custom_date_values_bool_exp> | null | undefined;
  custom_date_type?: custom_date_types_bool_exp | null | undefined;
  custom_date_type_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: custom_model_comparison_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  value?: date_comparison_exp | null | undefined;
};
export type custom_date_types_bool_exp = {
  _and?: ReadonlyArray<custom_date_types_bool_exp> | null | undefined;
  _not?: custom_date_types_bool_exp | null | undefined;
  _or?: ReadonlyArray<custom_date_types_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  description?: String_comparison_exp | null | undefined;
  filterable_in_planner?: Boolean_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: custom_model_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  required?: Boolean_comparison_exp | null | undefined;
  show_in_planner?: Boolean_comparison_exp | null | undefined;
  sort_order?: Int_comparison_exp | null | undefined;
};
export type custom_date_values_aggregate_bool_exp = {
  count?: custom_date_values_aggregate_bool_exp_count | null | undefined;
};
export type custom_date_values_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<custom_date_values_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: custom_date_values_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type custom_select_values_bool_exp = {
  _and?: ReadonlyArray<custom_select_values_bool_exp> | null | undefined;
  _not?: custom_select_values_bool_exp | null | undefined;
  _or?: ReadonlyArray<custom_select_values_bool_exp> | null | undefined;
  custom_select_option?: custom_select_options_bool_exp | null | undefined;
  custom_select_option_id?: Int_comparison_exp | null | undefined;
  custom_select_type_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: custom_model_comparison_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
};
export type custom_select_options_bool_exp = {
  _and?: ReadonlyArray<custom_select_options_bool_exp> | null | undefined;
  _not?: custom_select_options_bool_exp | null | undefined;
  _or?: ReadonlyArray<custom_select_options_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  custom_select_type?: custom_select_types_bool_exp | null | undefined;
  custom_select_type_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
};
export type custom_select_types_bool_exp = {
  _and?: ReadonlyArray<custom_select_types_bool_exp> | null | undefined;
  _not?: custom_select_types_bool_exp | null | undefined;
  _or?: ReadonlyArray<custom_select_types_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  custom_select_options?: custom_select_options_bool_exp | null | undefined;
  custom_select_options_aggregate?: custom_select_options_aggregate_bool_exp | null | undefined;
  description?: String_comparison_exp | null | undefined;
  filterable_in_planner?: Boolean_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: custom_model_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  required?: Boolean_comparison_exp | null | undefined;
  show_in_planner?: Boolean_comparison_exp | null | undefined;
  single_select?: Boolean_comparison_exp | null | undefined;
  sort_order?: Int_comparison_exp | null | undefined;
};
export type custom_select_options_aggregate_bool_exp = {
  count?: custom_select_options_aggregate_bool_exp_count | null | undefined;
};
export type custom_select_options_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<custom_select_options_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: custom_select_options_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type custom_select_values_aggregate_bool_exp = {
  count?: custom_select_values_aggregate_bool_exp_count | null | undefined;
};
export type custom_select_values_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<custom_select_values_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: custom_select_values_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type custom_text_values_bool_exp = {
  _and?: ReadonlyArray<custom_text_values_bool_exp> | null | undefined;
  _not?: custom_text_values_bool_exp | null | undefined;
  _or?: ReadonlyArray<custom_text_values_bool_exp> | null | undefined;
  custom_text_type?: custom_text_types_bool_exp | null | undefined;
  custom_text_type_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: custom_model_comparison_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  value?: String_comparison_exp | null | undefined;
};
export type custom_text_types_bool_exp = {
  _and?: ReadonlyArray<custom_text_types_bool_exp> | null | undefined;
  _not?: custom_text_types_bool_exp | null | undefined;
  _or?: ReadonlyArray<custom_text_types_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  description?: String_comparison_exp | null | undefined;
  filterable_in_planner?: Boolean_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: custom_model_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  required?: Boolean_comparison_exp | null | undefined;
  show_in_planner?: Boolean_comparison_exp | null | undefined;
  sort_order?: Int_comparison_exp | null | undefined;
};
export type custom_text_values_aggregate_bool_exp = {
  count?: custom_text_values_aggregate_bool_exp_count | null | undefined;
};
export type custom_text_values_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<custom_text_values_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: custom_text_values_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type ext_person_link_bool_exp = {
  _and?: ReadonlyArray<ext_person_link_bool_exp> | null | undefined;
  _not?: ext_person_link_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_person_link_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  created_by?: String_comparison_exp | null | undefined;
  ext_person?: ext_person_bool_exp | null | undefined;
  ext_person_id?: Int_comparison_exp | null | undefined;
  note?: String_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type ext_person_bool_exp = {
  _and?: ReadonlyArray<ext_person_bool_exp> | null | undefined;
  _not?: ext_person_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_person_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  deleted?: Boolean_comparison_exp | null | undefined;
  email?: String_comparison_exp | null | undefined;
  ext_current_contract?: ext_contract_bool_exp | null | undefined;
  ext_person_links?: ext_person_link_bool_exp | null | undefined;
  ext_team?: ext_team_bool_exp | null | undefined;
  ext_team_id?: Int_comparison_exp | null | undefined;
  external_id?: String_comparison_exp | null | undefined;
  first_name?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  image_key?: String_comparison_exp | null | undefined;
  integration?: integration_bool_exp | null | undefined;
  integration_id?: Int_comparison_exp | null | undefined;
  is_placeholder?: Boolean_comparison_exp | null | undefined;
  last_name?: String_comparison_exp | null | undefined;
  references?: jsonb_comparison_exp | null | undefined;
  remote_id?: String_comparison_exp | null | undefined;
  sync?: Boolean_comparison_exp | null | undefined;
  tags?: String_array_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type ext_contract_bool_exp = {
  _and?: ReadonlyArray<ext_contract_bool_exp> | null | undefined;
  _not?: ext_contract_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_contract_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  deleted?: Boolean_comparison_exp | null | undefined;
  employment_type?: String_comparison_exp | null | undefined;
  end_date?: timestamp_comparison_exp | null | undefined;
  ext_person_id?: Int_comparison_exp | null | undefined;
  ext_role?: ext_role_bool_exp | null | undefined;
  ext_role_id?: Int_comparison_exp | null | undefined;
  external_id?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  integration_id?: Int_comparison_exp | null | undefined;
  minutes_per_day?: Int_comparison_exp | null | undefined;
  remote_id?: String_comparison_exp | null | undefined;
  rostered_days?: Int_array_comparison_exp | null | undefined;
  start_date?: timestamp_comparison_exp | null | undefined;
  sync?: Boolean_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type ext_role_bool_exp = {
  _and?: ReadonlyArray<ext_role_bool_exp> | null | undefined;
  _not?: ext_role_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_role_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  deleted?: Boolean_comparison_exp | null | undefined;
  external_id?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  integration_id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  remote_id?: String_comparison_exp | null | undefined;
  sync?: Boolean_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type ext_team_bool_exp = {
  _and?: ReadonlyArray<ext_team_bool_exp> | null | undefined;
  _not?: ext_team_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_team_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  deleted?: Boolean_comparison_exp | null | undefined;
  external_id?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  integration_id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  remote_id?: String_comparison_exp | null | undefined;
  sync?: Boolean_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type integration_bool_exp = {
  _and?: ReadonlyArray<integration_bool_exp> | null | undefined;
  _not?: integration_bool_exp | null | undefined;
  _or?: ReadonlyArray<integration_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  category?: String_comparison_exp | null | undefined;
  config?: jsonb_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  ext_people?: ext_person_bool_exp | null | undefined;
  ext_people_aggregate?: ext_person_aggregate_bool_exp | null | undefined;
  ext_projects?: ext_project_bool_exp | null | undefined;
  ext_projects_aggregate?: ext_project_aggregate_bool_exp | null | undefined;
  ext_time_offs?: ext_time_off_bool_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  integration_service?: integration_service_bool_exp | null | undefined;
  integration_service_id?: Int_comparison_exp | null | undefined;
  integration_syncs?: integration_sync_bool_exp | null | undefined;
  last_sync_at?: timestamptz_comparison_exp | null | undefined;
  next_sync_at?: timestamptz_comparison_exp | null | undefined;
  state?: String_comparison_exp | null | undefined;
  sync_interval_minutes?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type ext_person_aggregate_bool_exp = {
  bool_and?: ext_person_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: ext_person_aggregate_bool_exp_bool_or | null | undefined;
  count?: ext_person_aggregate_bool_exp_count | null | undefined;
};
export type ext_person_aggregate_bool_exp_bool_and = {
  arguments: ext_person_select_column_ext_person_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: ext_person_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type ext_person_aggregate_bool_exp_bool_or = {
  arguments: ext_person_select_column_ext_person_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: ext_person_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type ext_person_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<ext_person_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: ext_person_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type ext_project_bool_exp = {
  _and?: ReadonlyArray<ext_project_bool_exp> | null | undefined;
  _not?: ext_project_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_project_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  confirmed?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  deleted?: Boolean_comparison_exp | null | undefined;
  emoji?: String_comparison_exp | null | undefined;
  expenses_budget?: numeric_comparison_exp | null | undefined;
  ext_project_links?: ext_project_link_bool_exp | null | undefined;
  ext_project_members?: ext_project_member_bool_exp | null | undefined;
  external_id?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  integration?: integration_bool_exp | null | undefined;
  integration_id?: Int_comparison_exp | null | undefined;
  is_template?: Boolean_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  pricing_model?: String_comparison_exp | null | undefined;
  priority?: String_comparison_exp | null | undefined;
  rate_type?: String_comparison_exp | null | undefined;
  references?: jsonb_comparison_exp | null | undefined;
  remote_id?: String_comparison_exp | null | undefined;
  remote_url?: String_comparison_exp | null | undefined;
  sync?: Boolean_comparison_exp | null | undefined;
  tags?: String_array_comparison_exp | null | undefined;
  total_budget?: numeric_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type ext_project_link_bool_exp = {
  _and?: ReadonlyArray<ext_project_link_bool_exp> | null | undefined;
  _not?: ext_project_link_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_project_link_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  created_by?: String_comparison_exp | null | undefined;
  ext_project?: ext_project_bool_exp | null | undefined;
  ext_project_id?: Int_comparison_exp | null | undefined;
  note?: String_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type ext_project_member_bool_exp = {
  _and?: ReadonlyArray<ext_project_member_bool_exp> | null | undefined;
  _not?: ext_project_member_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_project_member_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  deleted?: Boolean_comparison_exp | null | undefined;
  ext_person?: ext_person_bool_exp | null | undefined;
  ext_person_id?: Int_comparison_exp | null | undefined;
  ext_project?: ext_project_bool_exp | null | undefined;
  ext_project_id?: Int_comparison_exp | null | undefined;
  external_id?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  integration?: integration_bool_exp | null | undefined;
  integration_id?: Int_comparison_exp | null | undefined;
  is_placeholder?: Boolean_comparison_exp | null | undefined;
  remote_id?: String_comparison_exp | null | undefined;
  sync?: Boolean_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type ext_project_aggregate_bool_exp = {
  bool_and?: ext_project_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: ext_project_aggregate_bool_exp_bool_or | null | undefined;
  count?: ext_project_aggregate_bool_exp_count | null | undefined;
};
export type ext_project_aggregate_bool_exp_bool_and = {
  arguments: ext_project_select_column_ext_project_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: ext_project_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type ext_project_aggregate_bool_exp_bool_or = {
  arguments: ext_project_select_column_ext_project_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: ext_project_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type ext_project_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<ext_project_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: ext_project_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type ext_time_off_bool_exp = {
  _and?: ReadonlyArray<ext_time_off_bool_exp> | null | undefined;
  _not?: ext_time_off_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_time_off_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  deleted?: Boolean_comparison_exp | null | undefined;
  end_date?: timestamp_comparison_exp | null | undefined;
  ext_person?: ext_person_bool_exp | null | undefined;
  ext_person_id?: Int_comparison_exp | null | undefined;
  ext_time_off_links?: ext_time_off_link_bool_exp | null | undefined;
  external_id?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  integration?: integration_bool_exp | null | undefined;
  integration_id?: Int_comparison_exp | null | undefined;
  leave_type?: String_comparison_exp | null | undefined;
  note?: String_comparison_exp | null | undefined;
  remote_id?: String_comparison_exp | null | undefined;
  start_date?: timestamp_comparison_exp | null | undefined;
  sync?: Boolean_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type ext_time_off_link_bool_exp = {
  _and?: ReadonlyArray<ext_time_off_link_bool_exp> | null | undefined;
  _not?: ext_time_off_link_bool_exp | null | undefined;
  _or?: ReadonlyArray<ext_time_off_link_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  created_by?: String_comparison_exp | null | undefined;
  ext_time_off?: ext_time_off_bool_exp | null | undefined;
  ext_time_off_id?: Int_comparison_exp | null | undefined;
  note?: String_comparison_exp | null | undefined;
  time_off?: time_offs_bool_exp | null | undefined;
  time_off_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type time_offs_bool_exp = {
  _and?: ReadonlyArray<time_offs_bool_exp> | null | undefined;
  _not?: time_offs_bool_exp | null | undefined;
  _or?: ReadonlyArray<time_offs_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  end_date_iso?: date_comparison_exp | null | undefined;
  end_date_runn?: String_comparison_exp | null | undefined;
  ext_time_off_links?: ext_time_off_link_bool_exp | null | undefined;
  holiday?: holidays_bool_exp | null | undefined;
  holiday_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  leave_type?: String_comparison_exp | null | undefined;
  minutes_per_day?: Int_comparison_exp | null | undefined;
  note?: String_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  start_date_iso?: date_comparison_exp | null | undefined;
  start_date_runn?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type holidays_bool_exp = {
  _and?: ReadonlyArray<holidays_bool_exp> | null | undefined;
  _not?: holidays_bool_exp | null | undefined;
  _or?: ReadonlyArray<holidays_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  date?: date_comparison_exp | null | undefined;
  date_runn?: String_comparison_exp | null | undefined;
  holiday_api_uuid?: uuid_comparison_exp | null | undefined;
  holidays_group?: holidays_groups_bool_exp | null | undefined;
  holidays_group_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  observed?: date_comparison_exp | null | undefined;
  observed_runn?: String_comparison_exp | null | undefined;
  time_offs?: time_offs_bool_exp | null | undefined;
  time_offs_aggregate?: time_offs_aggregate_bool_exp | null | undefined;
  type?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type uuid_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type time_offs_aggregate_bool_exp = {
  count?: time_offs_aggregate_bool_exp_count | null | undefined;
};
export type time_offs_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<time_offs_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: time_offs_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type integration_service_bool_exp = {
  _and?: ReadonlyArray<integration_service_bool_exp> | null | undefined;
  _not?: integration_service_bool_exp | null | undefined;
  _or?: ReadonlyArray<integration_service_bool_exp> | null | undefined;
  active?: Boolean_comparison_exp | null | undefined;
  categories?: String_array_comparison_exp | null | undefined;
  color?: String_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  image_url?: String_comparison_exp | null | undefined;
  integrations?: integration_bool_exp | null | undefined;
  models?: String_array_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  platform?: String_comparison_exp | null | undefined;
  slug?: String_comparison_exp | null | undefined;
  square_image_url?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type integration_sync_bool_exp = {
  _and?: ReadonlyArray<integration_sync_bool_exp> | null | undefined;
  _not?: integration_sync_bool_exp | null | undefined;
  _or?: ReadonlyArray<integration_sync_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  finished_at?: timestamptz_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  info_messages?: String_array_comparison_exp | null | undefined;
  integration?: integration_bool_exp | null | undefined;
  integration_id?: Int_comparison_exp | null | undefined;
  started_at?: timestamptz_comparison_exp | null | undefined;
  state?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type timestamptz_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type people_links_bool_exp = {
  _and?: ReadonlyArray<people_links_bool_exp> | null | undefined;
  _not?: people_links_bool_exp | null | undefined;
  _or?: ReadonlyArray<people_links_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  href?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  show_in_planner?: Boolean_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type people_managers_bool_exp = {
  _and?: ReadonlyArray<people_managers_bool_exp> | null | undefined;
  _not?: people_managers_bool_exp | null | undefined;
  _or?: ReadonlyArray<people_managers_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
  user_id?: Int_comparison_exp | null | undefined;
};
export type users_bool_exp = {
  _and?: ReadonlyArray<users_bool_exp> | null | undefined;
  _not?: users_bool_exp | null | undefined;
  _or?: ReadonlyArray<users_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  current_sign_in_at?: timestamptz_comparison_exp | null | undefined;
  email?: String_comparison_exp | null | undefined;
  favourite_people?: jsonb_comparison_exp | null | undefined;
  favourite_projects?: jsonb_comparison_exp | null | undefined;
  filter_sets?: user_filter_sets_bool_exp | null | undefined;
  first_name?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  image_key?: String_comparison_exp | null | undefined;
  last_name?: String_comparison_exp | null | undefined;
  linkedPerson?: people_bool_exp | null | undefined;
  manageable_people?: people_managers_bool_exp | null | undefined;
  manageable_projects?: project_managers_bool_exp | null | undefined;
  novu_subscriber_id?: String_comparison_exp | null | undefined;
  people?: people_bool_exp | null | undefined;
  people_aggregate?: people_aggregate_bool_exp | null | undefined;
  permissions?: jsonb_comparison_exp | null | undefined;
  references?: jsonb_comparison_exp | null | undefined;
  role_name?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  view?: views_bool_exp | null | undefined;
  view_id?: Int_comparison_exp | null | undefined;
  viewed_welcome?: Boolean_comparison_exp | null | undefined;
};
export type user_filter_sets_bool_exp = {
  _and?: ReadonlyArray<user_filter_sets_bool_exp> | null | undefined;
  _not?: user_filter_sets_bool_exp | null | undefined;
  _or?: ReadonlyArray<user_filter_sets_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  filters?: json_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  type?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
  user_id?: Int_comparison_exp | null | undefined;
};
export type json_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type project_managers_bool_exp = {
  _and?: ReadonlyArray<project_managers_bool_exp> | null | undefined;
  _not?: project_managers_bool_exp | null | undefined;
  _or?: ReadonlyArray<project_managers_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  user_id?: Int_comparison_exp | null | undefined;
};
export type people_aggregate_bool_exp = {
  bool_and?: people_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: people_aggregate_bool_exp_bool_or | null | undefined;
  count?: people_aggregate_bool_exp_count | null | undefined;
};
export type people_aggregate_bool_exp_bool_and = {
  arguments: people_select_column_people_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: people_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type people_aggregate_bool_exp_bool_or = {
  arguments: people_select_column_people_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: people_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type people_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<people_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: people_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type views_bool_exp = {
  _and?: ReadonlyArray<views_bool_exp> | null | undefined;
  _not?: views_bool_exp | null | undefined;
  _or?: ReadonlyArray<views_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  description?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  people_filters?: jsonb_comparison_exp | null | undefined;
  project_filters?: jsonb_comparison_exp | null | undefined;
};
export type people_notes_bool_exp = {
  _and?: ReadonlyArray<people_notes_bool_exp> | null | undefined;
  _not?: people_notes_bool_exp | null | undefined;
  _or?: ReadonlyArray<people_notes_bool_exp> | null | undefined;
  created_at?: timestamptz_comparison_exp | null | undefined;
  created_by?: String_comparison_exp | null | undefined;
  creator_email?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  note?: String_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamptz_comparison_exp | null | undefined;
  user_id?: Int_comparison_exp | null | undefined;
};
export type people_notes_aggregate_bool_exp = {
  count?: people_notes_aggregate_bool_exp_count | null | undefined;
};
export type people_notes_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<people_notes_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: people_notes_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type person_requests_bool_exp = {
  _and?: ReadonlyArray<person_requests_bool_exp> | null | undefined;
  _not?: person_requests_bool_exp | null | undefined;
  _or?: ReadonlyArray<person_requests_bool_exp> | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  status?: person_request_statuses_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  updater_id?: Int_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
};
export type person_request_statuses_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type placeholder_suggestions_bool_exp = {
  _and?: ReadonlyArray<placeholder_suggestions_bool_exp> | null | undefined;
  _not?: placeholder_suggestions_bool_exp | null | undefined;
  _or?: ReadonlyArray<placeholder_suggestions_bool_exp> | null | undefined;
  placeholder?: people_bool_exp | null | undefined;
  placeholder_id?: Int_comparison_exp | null | undefined;
  suggested_at?: timestamp_comparison_exp | null | undefined;
  suggested_by?: Int_comparison_exp | null | undefined;
  suggested_by_user?: users_bool_exp | null | undefined;
  suggested_person?: people_bool_exp | null | undefined;
  suggested_person_id?: Int_comparison_exp | null | undefined;
};
export type teams_bool_exp = {
  _and?: ReadonlyArray<teams_bool_exp> | null | undefined;
  _not?: teams_bool_exp | null | undefined;
  _or?: ReadonlyArray<teams_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  people?: people_bool_exp | null | undefined;
  people_aggregate?: people_aggregate_bool_exp | null | undefined;
  projects?: projects_bool_exp | null | undefined;
  projects_aggregate?: projects_aggregate_bool_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type project_links_bool_exp = {
  _and?: ReadonlyArray<project_links_bool_exp> | null | undefined;
  _not?: project_links_bool_exp | null | undefined;
  _or?: ReadonlyArray<project_links_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  href?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  show_in_planner?: Boolean_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type milestones_bool_exp = {
  _and?: ReadonlyArray<milestones_bool_exp> | null | undefined;
  _not?: milestones_bool_exp | null | undefined;
  _or?: ReadonlyArray<milestones_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  date?: date_comparison_exp | null | undefined;
  date_runn?: String_comparison_exp | null | undefined;
  icon?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  note?: String_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  title?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type milestones_aggregate_bool_exp = {
  count?: milestones_aggregate_bool_exp_count | null | undefined;
};
export type milestones_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<milestones_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: milestones_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type notes_bool_exp = {
  _and?: ReadonlyArray<notes_bool_exp> | null | undefined;
  _not?: notes_bool_exp | null | undefined;
  _or?: ReadonlyArray<notes_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  created_by?: String_comparison_exp | null | undefined;
  creator_email?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  note?: String_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  user_id?: Int_comparison_exp | null | undefined;
};
export type notes_aggregate_bool_exp = {
  count?: notes_aggregate_bool_exp_count | null | undefined;
};
export type notes_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<notes_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: notes_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type other_costs_bool_exp = {
  _and?: ReadonlyArray<other_costs_bool_exp> | null | undefined;
  _not?: other_costs_bool_exp | null | undefined;
  _or?: ReadonlyArray<other_costs_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  charge?: numeric_comparison_exp | null | undefined;
  cost?: numeric_comparison_exp | null | undefined;
  date?: date_comparison_exp | null | undefined;
  date_runn?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
};
export type other_costs_aggregate_bool_exp = {
  count?: other_costs_aggregate_bool_exp_count | null | undefined;
};
export type other_costs_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<other_costs_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: other_costs_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type phases_aggregate_bool_exp = {
  count?: phases_aggregate_bool_exp_count | null | undefined;
};
export type phases_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<phases_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: phases_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type project_roles_bool_exp = {
  _and?: ReadonlyArray<project_roles_bool_exp> | null | undefined;
  _not?: project_roles_bool_exp | null | undefined;
  _or?: ReadonlyArray<project_roles_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  estimated_minutes?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  role_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type project_roles_aggregate_bool_exp = {
  count?: project_roles_aggregate_bool_exp_count | null | undefined;
};
export type project_roles_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<project_roles_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: project_roles_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type project_tags_bool_exp = {
  _and?: ReadonlyArray<project_tags_bool_exp> | null | undefined;
  _not?: project_tags_bool_exp | null | undefined;
  _or?: ReadonlyArray<project_tags_bool_exp> | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  tag?: tags_bool_exp | null | undefined;
  tag_id?: Int_comparison_exp | null | undefined;
};
export type tags_bool_exp = {
  _and?: ReadonlyArray<tags_bool_exp> | null | undefined;
  _not?: tags_bool_exp | null | undefined;
  _or?: ReadonlyArray<tags_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  archived?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: String_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type type_schedule_totals_bool_exp = {
  _and?: ReadonlyArray<type_schedule_totals_bool_exp> | null | undefined;
  _not?: type_schedule_totals_bool_exp | null | undefined;
  _or?: ReadonlyArray<type_schedule_totals_bool_exp> | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  sum_billable_minutes?: Int_comparison_exp | null | undefined;
  sum_nonbillable_minutes?: Int_comparison_exp | null | undefined;
};
export type v_summary_total_bool_exp = {
  _and?: ReadonlyArray<v_summary_total_bool_exp> | null | undefined;
  _not?: v_summary_total_bool_exp | null | undefined;
  _or?: ReadonlyArray<v_summary_total_bool_exp> | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  actual_phase_id?: Int_comparison_exp | null | undefined;
  assignment_phase_id?: Int_comparison_exp | null | undefined;
  minutes_phase_id?: Int_comparison_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  role_id?: Int_comparison_exp | null | undefined;
  total_actuals_billable_minutes?: numeric_comparison_exp | null | undefined;
  total_actuals_nonbillable_minutes?: numeric_comparison_exp | null | undefined;
  total_assignment_billable_minutes?: numeric_comparison_exp | null | undefined;
  total_assignment_nonbillable_minutes?: numeric_comparison_exp | null | undefined;
  total_billable_minutes?: numeric_comparison_exp | null | undefined;
  total_nonbillable_minutes?: numeric_comparison_exp | null | undefined;
  workstream_id?: Int_comparison_exp | null | undefined;
};
export type timesheet_lock_status_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type clients_aggregate_bool_exp = {
  bool_and?: clients_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: clients_aggregate_bool_exp_bool_or | null | undefined;
  count?: clients_aggregate_bool_exp_count | null | undefined;
};
export type clients_aggregate_bool_exp_bool_and = {
  arguments: clients_select_column_clients_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: clients_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type clients_aggregate_bool_exp_bool_or = {
  arguments: clients_select_column_clients_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: clients_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type clients_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<clients_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: clients_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type custom_checkbox_types_bool_exp = {
  _and?: ReadonlyArray<custom_checkbox_types_bool_exp> | null | undefined;
  _not?: custom_checkbox_types_bool_exp | null | undefined;
  _or?: ReadonlyArray<custom_checkbox_types_bool_exp> | null | undefined;
  custom_checkbox_values?: custom_checkbox_values_bool_exp | null | undefined;
  custom_checkbox_values_aggregate?: custom_checkbox_values_aggregate_bool_exp | null | undefined;
  description?: String_comparison_exp | null | undefined;
  filterable_in_planner?: Boolean_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: custom_model_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  required?: Boolean_comparison_exp | null | undefined;
  show_in_planner?: Boolean_comparison_exp | null | undefined;
  sort_order?: Int_comparison_exp | null | undefined;
};
export type custom_checkbox_types_aggregate_bool_exp = {
  bool_and?: custom_checkbox_types_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: custom_checkbox_types_aggregate_bool_exp_bool_or | null | undefined;
  count?: custom_checkbox_types_aggregate_bool_exp_count | null | undefined;
};
export type custom_checkbox_types_aggregate_bool_exp_bool_and = {
  arguments: custom_checkbox_types_select_column_custom_checkbox_types_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_checkbox_types_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_checkbox_types_aggregate_bool_exp_bool_or = {
  arguments: custom_checkbox_types_select_column_custom_checkbox_types_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_checkbox_types_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_checkbox_types_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<custom_checkbox_types_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: custom_checkbox_types_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type custom_date_types_aggregate_bool_exp = {
  bool_and?: custom_date_types_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: custom_date_types_aggregate_bool_exp_bool_or | null | undefined;
  count?: custom_date_types_aggregate_bool_exp_count | null | undefined;
};
export type custom_date_types_aggregate_bool_exp_bool_and = {
  arguments: custom_date_types_select_column_custom_date_types_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_date_types_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_date_types_aggregate_bool_exp_bool_or = {
  arguments: custom_date_types_select_column_custom_date_types_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_date_types_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_date_types_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<custom_date_types_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: custom_date_types_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type custom_select_types_aggregate_bool_exp = {
  bool_and?: custom_select_types_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: custom_select_types_aggregate_bool_exp_bool_or | null | undefined;
  count?: custom_select_types_aggregate_bool_exp_count | null | undefined;
};
export type custom_select_types_aggregate_bool_exp_bool_and = {
  arguments: custom_select_types_select_column_custom_select_types_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_select_types_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_select_types_aggregate_bool_exp_bool_or = {
  arguments: custom_select_types_select_column_custom_select_types_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_select_types_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_select_types_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<custom_select_types_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: custom_select_types_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type custom_text_types_aggregate_bool_exp = {
  bool_and?: custom_text_types_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: custom_text_types_aggregate_bool_exp_bool_or | null | undefined;
  count?: custom_text_types_aggregate_bool_exp_count | null | undefined;
};
export type custom_text_types_aggregate_bool_exp_bool_and = {
  arguments: custom_text_types_select_column_custom_text_types_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_text_types_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_text_types_aggregate_bool_exp_bool_or = {
  arguments: custom_text_types_select_column_custom_text_types_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: custom_text_types_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type custom_text_types_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<custom_text_types_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: custom_text_types_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type features_accounts_bool_exp = {
  _and?: ReadonlyArray<features_accounts_bool_exp> | null | undefined;
  _not?: features_accounts_bool_exp | null | undefined;
  _or?: ReadonlyArray<features_accounts_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  enabled?: Boolean_comparison_exp | null | undefined;
  feature?: features_bool_exp | null | undefined;
  feature_id?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
};
export type features_bool_exp = {
  _and?: ReadonlyArray<features_bool_exp> | null | undefined;
  _not?: features_bool_exp | null | undefined;
  _or?: ReadonlyArray<features_bool_exp> | null | undefined;
  description?: String_comparison_exp | null | undefined;
  enabled?: Boolean_comparison_exp | null | undefined;
  features_accounts?: features_accounts_bool_exp | null | undefined;
  id?: String_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
};
export type holidays_aggregate_bool_exp = {
  count?: holidays_aggregate_bool_exp_count | null | undefined;
};
export type holidays_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<holidays_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: holidays_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type holidays_groups_aggregate_bool_exp = {
  count?: holidays_groups_aggregate_bool_exp_count | null | undefined;
};
export type holidays_groups_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<holidays_groups_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: holidays_groups_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type invitations_bool_exp = {
  _and?: ReadonlyArray<invitations_bool_exp> | null | undefined;
  _not?: invitations_bool_exp | null | undefined;
  _or?: ReadonlyArray<invitations_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  add_all_people_to_projects_permission?: Boolean_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  email?: String_comparison_exp | null | undefined;
  expires_at?: timestamptz_comparison_exp | null | undefined;
  fin_permission?: String_comparison_exp | null | undefined;
  from_user_id?: Int_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  manage_account_permission?: Boolean_comparison_exp | null | undefined;
  manage_people_permission?: String_comparison_exp | null | undefined;
  manage_projects_permission?: String_comparison_exp | null | undefined;
  manageable_people?: invitation_people_managers_bool_exp | null | undefined;
  manageable_people_aggregate?: invitation_people_managers_aggregate_bool_exp | null | undefined;
  manageable_projects?: invitation_project_managers_bool_exp | null | undefined;
  manageable_projects_aggregate?: invitation_project_managers_aggregate_bool_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  user_type?: String_comparison_exp | null | undefined;
  uuid?: String_comparison_exp | null | undefined;
  view_planner_permission?: Boolean_comparison_exp | null | undefined;
};
export type invitation_people_managers_bool_exp = {
  _and?: ReadonlyArray<invitation_people_managers_bool_exp> | null | undefined;
  _not?: invitation_people_managers_bool_exp | null | undefined;
  _or?: ReadonlyArray<invitation_people_managers_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  invitation?: invitations_bool_exp | null | undefined;
  invitation_id?: Int_comparison_exp | null | undefined;
  person?: people_bool_exp | null | undefined;
  person_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type invitation_people_managers_aggregate_bool_exp = {
  count?: invitation_people_managers_aggregate_bool_exp_count | null | undefined;
};
export type invitation_people_managers_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<invitation_people_managers_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: invitation_people_managers_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type invitation_project_managers_bool_exp = {
  _and?: ReadonlyArray<invitation_project_managers_bool_exp> | null | undefined;
  _not?: invitation_project_managers_bool_exp | null | undefined;
  _or?: ReadonlyArray<invitation_project_managers_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  invitation?: invitations_bool_exp | null | undefined;
  invitation_id?: Int_comparison_exp | null | undefined;
  project?: projects_bool_exp | null | undefined;
  project_id?: Int_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
};
export type invitation_project_managers_aggregate_bool_exp = {
  count?: invitation_project_managers_aggregate_bool_exp_count | null | undefined;
};
export type invitation_project_managers_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<invitation_project_managers_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: invitation_project_managers_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type invitations_aggregate_bool_exp = {
  bool_and?: invitations_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: invitations_aggregate_bool_exp_bool_or | null | undefined;
  count?: invitations_aggregate_bool_exp_count | null | undefined;
};
export type invitations_aggregate_bool_exp_bool_and = {
  arguments: invitations_select_column_invitations_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: invitations_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type invitations_aggregate_bool_exp_bool_or = {
  arguments: invitations_select_column_invitations_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: invitations_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type invitations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<invitations_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: invitations_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type rate_cards_aggregate_bool_exp = {
  bool_and?: rate_cards_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: rate_cards_aggregate_bool_exp_bool_or | null | undefined;
  count?: rate_cards_aggregate_bool_exp_count | null | undefined;
};
export type rate_cards_aggregate_bool_exp_bool_and = {
  arguments: rate_cards_select_column_rate_cards_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rate_cards_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type rate_cards_aggregate_bool_exp_bool_or = {
  arguments: rate_cards_select_column_rate_cards_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rate_cards_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type rate_cards_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<rate_cards_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: rate_cards_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type roles_aggregate_bool_exp = {
  bool_and?: roles_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: roles_aggregate_bool_exp_bool_or | null | undefined;
  count?: roles_aggregate_bool_exp_count | null | undefined;
};
export type roles_aggregate_bool_exp_bool_and = {
  arguments: roles_select_column_roles_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: roles_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type roles_aggregate_bool_exp_bool_or = {
  arguments: roles_select_column_roles_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: roles_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type roles_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<roles_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: roles_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type saved_reports_bool_exp = {
  _and?: ReadonlyArray<saved_reports_bool_exp> | null | undefined;
  _not?: saved_reports_bool_exp | null | undefined;
  _or?: ReadonlyArray<saved_reports_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  created_at?: timestamp_comparison_exp | null | undefined;
  creator_id?: Int_comparison_exp | null | undefined;
  hash?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  preset?: reports_bool_exp | null | undefined;
  preset_id?: Int_comparison_exp | null | undefined;
  state?: jsonb_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  updater_id?: Int_comparison_exp | null | undefined;
};
export type reports_bool_exp = {
  _and?: ReadonlyArray<reports_bool_exp> | null | undefined;
  _not?: reports_bool_exp | null | undefined;
  _or?: ReadonlyArray<reports_bool_exp> | null | undefined;
  description?: String_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  model?: custom_model_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  navigation_name?: String_comparison_exp | null | undefined;
  slug?: String_comparison_exp | null | undefined;
  upgraded?: Boolean_comparison_exp | null | undefined;
};
export type skills_aggregate_bool_exp = {
  count?: skills_aggregate_bool_exp_count | null | undefined;
};
export type skills_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<skills_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: skills_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type tags_aggregate_bool_exp = {
  bool_and?: tags_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: tags_aggregate_bool_exp_bool_or | null | undefined;
  count?: tags_aggregate_bool_exp_count | null | undefined;
};
export type tags_aggregate_bool_exp_bool_and = {
  arguments: tags_select_column_tags_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: tags_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type tags_aggregate_bool_exp_bool_or = {
  arguments: tags_select_column_tags_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: tags_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type tags_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<tags_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: tags_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type teams_aggregate_bool_exp = {
  count?: teams_aggregate_bool_exp_count | null | undefined;
};
export type teams_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<teams_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: teams_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type user_accounts_bool_exp = {
  _and?: ReadonlyArray<user_accounts_bool_exp> | null | undefined;
  _not?: user_accounts_bool_exp | null | undefined;
  _or?: ReadonlyArray<user_accounts_bool_exp> | null | undefined;
  account?: accounts_bool_exp | null | undefined;
  account_id?: Int_comparison_exp | null | undefined;
  favourite_people?: jsonb_comparison_exp | null | undefined;
  favourite_projects?: jsonb_comparison_exp | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  notification_subscription?: notification_subscriptions_bool_exp | null | undefined;
  permissions?: jsonb_comparison_exp | null | undefined;
  updated_at?: timestamp_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
  user_id?: Int_comparison_exp | null | undefined;
};
export type notification_subscriptions_bool_exp = {
  _and?: ReadonlyArray<notification_subscriptions_bool_exp> | null | undefined;
  _not?: notification_subscriptions_bool_exp | null | undefined;
  _or?: ReadonlyArray<notification_subscriptions_bool_exp> | null | undefined;
  id?: Int_comparison_exp | null | undefined;
  people_filters?: jsonb_comparison_exp | null | undefined;
  project_filters?: jsonb_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
};
export type users_aggregate_bool_exp = {
  bool_and?: users_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: users_aggregate_bool_exp_bool_or | null | undefined;
  count?: users_aggregate_bool_exp_count | null | undefined;
};
export type users_aggregate_bool_exp_bool_and = {
  arguments: users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: users_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type users_aggregate_bool_exp_bool_or = {
  arguments: users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: users_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type users_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<users_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: users_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type views_aggregate_bool_exp = {
  count?: views_aggregate_bool_exp_count | null | undefined;
};
export type views_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<views_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: views_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type workstreams_aggregate_bool_exp = {
  bool_and?: workstreams_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: workstreams_aggregate_bool_exp_bool_or | null | undefined;
  count?: workstreams_aggregate_bool_exp_count | null | undefined;
};
export type workstreams_aggregate_bool_exp_bool_and = {
  arguments: workstreams_select_column_workstreams_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: workstreams_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type workstreams_aggregate_bool_exp_bool_or = {
  arguments: workstreams_select_column_workstreams_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: workstreams_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type workstreams_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<workstreams_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: workstreams_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type holidays_on_conflict = {
  constraint: holidays_constraint;
  update_columns?: ReadonlyArray<holidays_update_column>;
  where?: holidays_bool_exp | null | undefined;
};
export type HolidaysGroupCreateMutation$variables = {
  group: holidays_groups_insert_input;
};
export type HolidaysGroupCreateMutation$data = {
  readonly insert_holidays_groups_one: {
    readonly account: {
      readonly holidays_groups: ReadonlyArray<{
        readonly country_code: string;
        readonly country_name: string | null | undefined;
        readonly holidays: ReadonlyArray<{
          readonly id: number;
        }>;
        readonly id: number;
        readonly name: string;
        readonly region_name: string | null | undefined;
      }>;
      readonly id: number;
    };
    readonly id: number;
    readonly name: string;
  } | null | undefined;
};
export type HolidaysGroupCreateMutation = {
  response: HolidaysGroupCreateMutation$data;
  variables: HolidaysGroupCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "group"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "group"
      }
    ],
    "concreteType": "holidays_groups",
    "kind": "LinkedField",
    "name": "insert_holidays_groups_one",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "order_by",
                "value": {
                  "name": "asc"
                }
              }
            ],
            "concreteType": "holidays_groups",
            "kind": "LinkedField",
            "name": "holidays_groups",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country_code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "region_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "holidays",
                "kind": "LinkedField",
                "name": "holidays",
                "plural": true,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "holidays_groups(order_by:{\"name\":\"asc\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HolidaysGroupCreateMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HolidaysGroupCreateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "3be5cf208a28ca8a29f12346c8d98507",
    "id": null,
    "metadata": {},
    "name": "HolidaysGroupCreateMutation",
    "operationKind": "mutation",
    "text": "mutation HolidaysGroupCreateMutation(\n  $group: holidays_groups_insert_input!\n) {\n  insert_holidays_groups_one(object: $group) {\n    id\n    name\n    account {\n      id\n      holidays_groups(order_by: {name: asc}) {\n        id\n        name\n        country_code\n        region_name\n        country_name\n        holidays {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b98294f376be7e1dbe57b9c77c3e54a2";

export default node;
